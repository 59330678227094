jQuery(function($) {

var userFeed = new Instafeed({ 
get: 'user', 
userId: 4233277342, 
accessToken: '4233277342.3a81a9f.768363504f9241488f187c3b040de8bd', 
limit: 12, 
sortBy: "random",

template: '<li><a href="{{link}}" target="_blank"><img src="{{image}}" alt="{{caption}}" /></a></li>', after: function() { $('#scroller ul').simplyScroll(); }, }); userFeed.run();

});